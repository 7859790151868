<template>
  <c-card>
    <b-alert :show="!syncStatus" variant="primary">
      <div class="alert-body">
        Anda belum melakukan sinkronisasi data server. silahkan klik tombol <b><feather-icon icon="CloudDownloadIcon"></feather-icon> Sinkronisasi Data Server</b> untuk melanjutkan input penjualan.
      </div>
    </b-alert>
    <b-alert :show="$route.query.uniqid||$route.query.id ? true : false" variant="primary">
      <div class="alert-body">
        Anda sedang melakukan edit transaksi. Tekan tombol <b class="text-danger"><feather-icon icon="XIcon"></feather-icon> Reset</b> jika ingin membatalkan edit dan melakukan transaksi baru.
      </div>
    </b-alert>
    <div class="d-flex justify-content-end">
      <small>Last Syncronize : <span class="font-weight-bolder">{{lastSync | fulldate}}</span></small>
    </div>
    <b-row>
      <b-col sm="12" offset-xl="2" xl="10">
        <b-row cols-sm="2" cols-xl="4">
          <b-col>
            <form-input readonly :label-cols="3" size="sm" v-model="formData.code_ref" label="No"></form-input>
          </b-col>
          <b-col>
            <form-date disabled size="sm" :label-cols="3" v-model="formData.date" label="Tgl"></form-date>
          </b-col>
          <b-col>
            <b-button @click="$refs.transactionModal.show()" block size="sm" variant="primary"><feather-icon icon="SearchIcon"></feather-icon> Cari Penjualan</b-button>
          </b-col>
          <b-col>
            <c-button @click="syncItems" :loading="syncLoading" block size="sm"><feather-icon icon="DownloadCloudIcon"></feather-icon> Sinkronisasi Data Server</c-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <c-form ref="form" @submit="confirmSubmit">
      <b-table-simple class="mb-2 mt-2" striped borderless outlined hover>
        <b-thead>
          <b-tr>
            <b-th>Kode Barang</b-th>
            <b-th>Merek</b-th>
            <b-th>Artikel</b-th>
            <b-th>Warna</b-th>
            <b-th>Nomor</b-th>
            <b-th>Qty</b-th>
            <b-th>Harga</b-th>
            <b-th>Total</b-th>
            <b-th></b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr class="pointer" v-for="(v,k) in details" :key="k">
            <b-td @click="itemsEdit(k)">{{v.code}}</b-td>
            <b-td @click="itemsEdit(k)">{{v.brand_name}}</b-td>
            <b-td @click="itemsEdit(k)">{{v.code_article}}</b-td>
            <b-td @click="itemsEdit(k)">{{v.color_name}}</b-td>
            <b-td @click="itemsEdit(k)">{{v.size_name}}</b-td>
            <b-td @click="itemsEdit(k)">{{v.qty | formatNumber}}</b-td>
            <b-td @click="itemsEdit(k)">Rp.{{v.price | formatNumber}}</b-td>
            <b-td @click="itemsEdit(k)">Rp.{{v.price_total | formatNumber}}</b-td>
            <b-td class="d-flex justify-content-end">
              <b-button @click="removeItems(k)" size="sm" variant="outline-danger">X</b-button>
            </b-td>
          </b-tr>
          <b-tr>
            <b-td>
              <b-button @click="openItemsModal" block variant="outline-primary"><feather-icon icon="MonitorIcon"></feather-icon> Cari Produk</b-button>
            </b-td>
            <b-td colspan="8"></b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <b-row>
        <b-col sm="12" xl="4" order="1" order-xl="0">
          <p>
            Jumlah Item : <span class="font-weight-bolder">{{totalQty}}</span><br>
            Dilayani Oleh : <span class="font-weight-bolder">{{auth.name}}</span>
          </p>
          <form-textarea v-model="formData.note" label="Catatan Penjualan"></form-textarea>
          <b-button-group size="sm">
            <b-button @click="doPrint" variant="primary"><feather-icon icon="PrinterIcon"></feather-icon></b-button>
            <b-button @click="confirmReset" variant="danger"><feather-icon icon="XIcon"></feather-icon> Reset</b-button>
          </b-button-group>
          <p class="small mt-1">
            Printer :
            <span class="font-weight-bolder">{{printerStatus}}</span>
          </p>
        </b-col>
        <b-col sm="12" offset-sm="4" xl="4" order="0" order-xl="1">
          <form-number left-label readonly :value="subTotal" label="Sub Total"></form-number>
          <div class="d-flex justify-content-end">
            <form-number :rules="{required: true}" @onChange="discPercentChange" class="w-100 mr-1" left-label v-model.number="formData.disc_percent" label="Diskon"></form-number>
            <form-number :rules="{required: true}" placeholder="Diskon" @onChange="discTotalChange" class="w-100" v-model.number="formData.disc_total"></form-number>
          </div>
          <form-number left-label readonly :value="grandTotal" label="Tot. Penjualan"></form-number>
          <b-form-group label-cols-sm="4" label="Tipe Pembayaran" label-for="paymentTypeInput">
            <b-form-radio-group @change="paymentTypeChange" id="paymentTypeInput" button-variant="outline-primary" v-model="formData.id_payment_partner" :options="paymentTypes" name="paymentTypeInput" buttons text-field="label" value-field="value"></b-form-radio-group>
          </b-form-group>
          <form-number :rules="{required: true, minimal: [grandTotal]}" ref="paymentInput" size="lg" left-label v-model.number="formData.payment_amount" label="Bayar"></form-number>
          <form-number disabled left-label :value="paymentChange" label="Kembalian"></form-number>
        </b-col>
      </b-row>
      <c-button :disabled="!syncStatus" class="mt-3" size="lg" block type="submit">Simpan dan Cetak Nota</c-button>
    </c-form>
    <!-- MODAL CARI PRODUK -->
    <b-modal lazy size="lg" @shown="$refs.itemsSearch.focus()" centered ref="itemsModal" title="Cari Produk" no-close-on-backdrop hide-footer>
      <c-form @submit="importing">
        <b-row cols="1" cols-xl="2">
          <b-col>
            <form-input size="sm" ref="itemsSearch" v-model="searchItem" label="Daftar Produk" placeholder="Cari Berdasarkan Nama / Kode / Merek"></form-input>
          </b-col>
          <b-col>
            <form-select-static clearable size="sm" v-model="categoryItem" label="Kategori" :options="itemCategories" placeholder="Cari Berdasarkan Kategori"></form-select-static>
          </b-col>
          <b-col>
            <form-select-static clearable size="sm" v-model="colorItem" label="Warna" :options="itemColors" placeholder="Cari Berdasarkan Warna"></form-select-static>
          </b-col>
          <b-col>
            <form-select-static clearable size="sm" v-model="sizeItem" label="Ukuran" :options="itemSizes" placeholder="Cari Berdasarkan Ukuran"></form-select-static>
          </b-col>
        </b-row>
        <hr>
        <b-table sticky-header no-border-collapse outlined @row-selected="onItemsSelected" selectable select-mode="multi" selected-variant="primary" :fields="itemsField" :items="itemsFiltering" small responsive>
          <template #cell(sale_price)="{item}">
            Rp.{{item.sale_price | formatNumber}}
          </template>
        </b-table>
        <div class="d-flex justify-content-end">
          <b-button type="submit" class="mr-1" variant="primary">Import</b-button>
          <b-button @click="$refs.itemsModal.hide()" variant="danger">Tutup</b-button>
        </div>
      </c-form>
    </b-modal>

    <!-- MODAL EDIT PRODUK -->
    <b-modal lazy centered @shown="$refs.editQty.focus()" @hidden="discPercentChange" size="md" ref="editModal" title="Edit Produk" no-close-on-backdrop hide-footer>
      <c-form ref="editForm" @submit="submitEdit">
        <form-input disabled v-model="formEdit.code" label="Kode Barang"></form-input>
        <form-select-all disabled v-model="formEdit.brand" endpoint="v1/brands/select2" label="Merek"></form-select-all>
        <form-input v-model="formEdit.code_article" label="Artikel" disabled></form-input>
        <form-select-static disabled v-model="formEdit.color" :options="itemColors" label="Warna"></form-select-static>
        <form-select-static @option:selected="changeItemSize" v-model="formEdit.size" :options="sizes" label="Ukuran"></form-select-static>
        <form-number ref="editQty" :rules="{required: true}" @onChange="formEdit.price_total = formEdit.qty * formEdit.price" v-model.number="formEdit.qty" label="Qty"></form-number>
        <form-number disabled :value="formEdit.price" label="Harga"></form-number>
        <form-number disabled :value="formEdit.price_total" label="Total Harga"></form-number>
        <b-button class="mt-2" variant="primary" block type="submit">Ubah Produk</b-button>
      </c-form>
    </b-modal>

    <b-modal lazy ref="transactionModal" title="Cari Transaksi Penjualan" hide-footer size="xl">
      <b-tabs pills>
        <b-tab lazy title="Transaksi Hari Ini">
          <Local @imported="localTransactionImported"></Local>
        </b-tab>
        <b-tab lazy title="Transaksi Sebelumnya">
          <Server @imported="serverTransactionImported"></Server>
        </b-tab>
      </b-tabs>
    </b-modal>
  </c-card>
</template>

<script>
import CCard from '@/utils/components/CCard.vue'
import {BTableSimple,BThead,BTr,BTh,BFormRadioGroup,BFormGroup,BButton,BTbody,BTd,BModal,BTable,BButtonGroup,BAlert,BTabs,BTab} from 'bootstrap-vue'
import FormTextarea from '@/utils/components/FormTextarea.vue'
import CForm from '@/utils/components/CForm.vue'
import FormNumber from '@/utils/components/FormNumber.vue'
import FormDate from '@/utils/components/FormDate.vue'
import FormInput from '@/utils/components/FormInput.vue'
import CButton from '@/utils/components/CButton.vue'
import FormSelectAll from '@/utils/components/FormSelectAll.vue'
import FormSelectStatic from '@/utils/components/FormSelectStatic.vue'
import find from 'lodash/find'
import sumBy from 'lodash/sumBy'
import map from 'lodash/map'
import findIndex from 'lodash/findIndex'
import { v4 as uuidv4 } from 'uuid'
import helper from '../../../utils/js/helper'
import ScannerDetector from 'js-scanner-detection'
import printMixin from '../../../utils/mixins/qz-tray'
import {set_text_left,set_text_right,textToBase64Barcode} from '../../../utils/js/prints'
import Local from './components/Local.vue'
import Server from './components/Server.vue'

export default {
  mixins:[printMixin],
  components: {
    CCard,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    FormTextarea,
    CForm,
    FormNumber,
    BFormRadioGroup,
    BFormGroup,
    BButton,
    FormDate,
    FormInput,
    CButton,
    BTbody,
    BTd,
    BModal,
    BTable,
    FormSelectAll,
    FormSelectStatic,
    BButtonGroup,
    BAlert,
    Local,
    BTabs,
    BTab,
    Server
  },
  data(){
    const onComplete = (barcode) => {
      this.barcodeScanned(barcode)
    }
    const options = {
      onComplete,
      stopPropagation: true,
      preventDefault: false,
    }

    const formData = {
      code:'',
      code_ref:'',
      note:'',
      date:this.$moment(),
      disc_percent:0,
      disc_total:0,
      id_payment_partner:null,
      payment_amount:0,
      payment_change:0,
      is_sync: false,
      type_transaction: 3, // 3 = penjualan, 4 = penukaran
      kind: 1, //1 = tambah, 2 = edit, 3 = hapus
    }
    const details = []
    const itemsField = [
      {key:'code',label:'Kode Barang'},
      {key:'brand_name',label:'Merek'},
      {key:'item_name',label:'Artikel'},
      {key:'color_name',label:'Warna'},
      {key:'size_name',label:'Ukuran'},
      {key:'sale_price',label:'Harga'},
    ]
    const formEdit = {
      id_variant:null,
      id_item:null,
      code:'',
      brand: null,
      color:null,
      size:null,
      qty:1,
      code_article:'',
      price:0,
      price_total:0
    }
    return {
      formData,
      syncLoading:false,
      details,
      searchItem:'',
      colorItem:null,
      sizeItem:null,
      categoryItem:null,
      itemsField,
      selectedItems:[],
      formEdit,
      editIndex:-1,
      lastSync: localStorage.getItem('itemsLastSync'),
      scannerDetect : new ScannerDetector(options),
      scancode:""
    }
  },
  computed:{
    items(){
      return this.$store.state.pos.items
    },
    itemsFiltering(){
      const filtering = {}
      if(this.colorItem) filtering.id_color = this.colorItem.value
      if(this.sizeItem) filtering.id_size = this.sizeItem.value
      if(this.categoryItem) filtering.id_category = this.categoryItem.value
      if(this.searchItem) filtering.search = this.searchItem
      filtering.excludeId = map(this.details,'id_variant')
      return this.$store.getters['pos/itemsByFilter'](filtering)
    },
    paymentTypes(){
      return this.$store.state.pos.payment_types
    },
    totalQty(){
      return sumBy(this.details,'qty')
    },
    subTotal(){
      return sumBy(this.details,n => parseFloat(n.price_total))
    },
    grandTotal(){
      const {disc_total} = this.formData
      const subTotal = this.subTotal
      return subTotal-parseFloat(disc_total)
    },
    paymentChange(){
      if(!this.formData.payment_amount) return 0
      else return this.formData.payment_amount - this.grandTotal
    },
    itemColors(){
      return this.$store.getters['pos/getItemColors']
    },
    itemSizes(){
      return this.$store.getters['pos/getItemSizes']
    },
    itemCategories(){
      return this.$store.getters['pos/getItemCategories']
    },
    sizes(){
      const { id_item, brand, color } = this.formEdit
      const filter = {
        item_id: id_item,
      }
      if(brand) filter.id_brand = brand.value
      if(color) filter.id_color = color.value
      return this.$store.getters['pos/getFilteredSize'](filter)
    },
    syncStatus(){
      return this.$store.getters['pos/checkSyncStatus']
    }
  },
  watch:{
    '$route'(){
      this.incrementKey()
    }
  },
  methods:{
    confirmSubmit(){
      this.confirmation(`submitForm`)
    },
    async submitForm() {
      const uniqid = uuidv4()
      const formData = JSON.parse(JSON.stringify(this.formData))
      if(!this.$route.query.uniqid&&!this.$route.query.id) {
        formData.uniqid = uniqid
        localStorage.setItem('saleNumbering', formData.code_ref)
        formData.date = this.$moment()
      }
      formData.staff = this.auth.username
      formData.subtotal = this.subTotal
      formData.grand_total = this.grandTotal
      formData.payment_change = this.paymentChange
      formData.payment_partner = find(this.paymentTypes,{"value":formData.id_payment_partner}).label
      formData.details = this.details
      formData.total_item = this.totalQty
      this.$store.dispatch('pos/storeSale',formData)
      try {
        await this.doPrint()
        this.$nextTick(()=>{
          this.resetForm()
        })
      } catch (error) {
        this.$nextTick(()=>{
          this.resetForm()
        })
      }
    },
    confirmReset(){
      this.confirmation(`resetForm`)
    },
    resetForm() {
      this.details = []
      const fd = {
        code:'',
        code_ref:this.generatedCode(),
        note:'',
        date:this.$moment(),
        disc_percent:0,
        disc_total:0,
        id_payment_partner:null,
        payment_amount:0,
        payment_change:0,
        is_sync: false,
        type_transaction: 3,
        kind: 1
      }
      this.formData = fd
      this.formData.id_payment_partner = this.paymentTypes[0].value
      this.$router.replace({query:null})
    },
    async syncItems(){
      this.syncLoading = true
      Promise.all([
        this.$store.dispatch(`pos/getItems`),
        this.$store.dispatch(`pos/getPaymentTypes`),
      ]).then(()=>{
        if(this.paymentTypes.length>0)
          this.formData.id_payment_partner = this.paymentTypes[0].value
        this.lastSync = localStorage.getItem('itemsLastSync')
        this.syncLoading = false
      })
    },
    openItemsModal(){
      this.searchItem = ''
      this.$refs.itemsModal.show()
    },
    onItemsSelected(items){
      this.selectedItems = items
    },
    async doPrint(){
      const code = this.formData.code_ref.toUpperCase()
      // const chr = function(n) { return String.fromCharCode(n) }
      // TOTAL PANJANG PRINTER : 48 KARAKTER
      let data = [
        //  { type: 'raw', format: 'image', flavor: 'file', data: 'assets/img/image_sample_bw.png', options: { language: "ESCPOS", dotDensity: 'double' } },
        '\x1B' + '\x40',          // init
        '\x1B' + '\x61' + '\x31', // center align
        {type:'raw',format:'image',data: '/img/cahaya.png', options: { language: "ESCPOS", dotDensity: 'double' }},
        'TOKO SEPATU DAN TAS',
        '\x0A',                   // line break
        '\x1B' + '\x21' + '\x30', // em mode on
        'CAHAYA',     // text and line break
        '\x1B' + '\x21' + '\x0A' + '\x1B' + '\x45', // em mode off
        '\x0A',                   // line break
        '\x0A',                   // line break
        'JL. JEND A.YANI 48 - GARUT' + '\x0A',
        '\x0A',
        '\x1B' + '\x61' + '\x30', // left align
        set_text_left('NO. NOTA',15)+': '+set_text_left(this.formData.code_ref,25),
        '\x0A',
        set_text_left('TGL.',15)+': '+set_text_left(this.$moment(this.formData.date).format('DD-MM-YYYY - HH:mm'),25),
        '\x0A',
        set_text_left('KASIR',15)+': '+set_text_left(this.auth.username,25),
        '\x0A',
        '\x0A',
        '\x1B\x2D\x01', // turn on underline
        set_text_left('QTY',5)+set_text_left('NAMA BARANG',33)+set_text_right('TOTAL',10),
        '\x1B\x2D\x00', // turn off underline
        '\x0A',
        '\x0A'
        ]
        for (let i = 0; i < this.details.length; i++) {
          const val = this.details[i];
          data = [...data,...[
            set_text_left(`${val.qty}`,5)+set_text_left(`${val.brand_name}, ${val.code_article}`,33)+set_text_right(`${this.$options.filters.formatNumber(val.price_total)}`,10),
            '\x0A',
            set_text_left('',5)+set_text_left(`${val.color_name}, ${val.size_name}`,33),
            '\x0A'
          ]]
        }
        //  '------------------------------------------------',
        data = [...data,...[
        /*
        '\x1B\x2D\x01', // turn on underline
        set_text_left('',48),
        '\x1B\x2D\x00', // turn off underline
        */
        '\x0A',
        set_text_left('',23)+set_text_left('SUB TOTAL',15)+set_text_right(this.$options.filters.formatNumber(this.subTotal),10),
        '\x0A',
        set_text_left('',23)+set_text_left('DISKON',15)+set_text_right(this.$options.filters.formatNumber(this.formData.disc_total),10),
        '\x0A',
        set_text_left('',23)+set_text_left('TOTAL',15)+set_text_right(this.$options.filters.formatNumber(this.grandTotal),10),
        '\x0A',
        set_text_left('',23)+set_text_left('CASH',15)+set_text_right(this.$options.filters.formatNumber(this.formData.payment_amount),10),
        '\x0A',
        set_text_left('',23)+set_text_left('KEMBALIAN',15)+set_text_right(this.$options.filters.formatNumber(this.paymentChange),10),
        '\x0A',
        '\x0A',
        '\x1B' + '\x61' + '\x31',  // center align
        /*
        '\x1D' + 'h' + chr(80) +   // barcode height
        '\x1D' + 'f' + chr(0) +    // font for printed number
        '\x1D' + 'k' + chr(69) + chr(code.length) + code + chr(0),
        */
        '* BARANG YANG SUDAH DIBELI TIDAK DAPAT DITUKAR/DIKEMBALIKAN, KECUALI ADA PERJANJIAN',
        '\x0A',
        {type:'image',format:'base64',data: textToBase64Barcode(code), options: { language: "ESCPOS", dotDensity: 'double' }},
        '\x0A',
        'TERIMA KASIH',
        '\x0A',
        'SILAHKAN DATANG KEMBALI',
        '\x0A',
        '\x1B' + '\x4D' + '\x31', // small
        'IMSPOS © '+this.$moment().format('YYYY'),
        '\x0A',
        '\x0A',
        '\x1B' + '\x4D' + '\x30', // normal
        '*** CUSTOMER COPY ***',
        '\x0A' + '\x0A' + '\x0A' + '\x0A' + '\x0A' + '\x0A',
        '\x1B' + '\x69',           // cut paper (old syntax),
        ]]
        await this.qzPrint(data)
    },
    importing(){
      let dt = JSON.parse(JSON.stringify(this.details))
      for (let i = 0; i < this.selectedItems.length; i++) {
        const e = this.selectedItems[i];
        const findExist = find(dt,{id_variant:e.id})
        if(!findExist) {
          dt.push({
            uniqid: uuidv4(),
            id_variant: e.id,
            code: e.code,
            code_article: e.code_article,
            id_color: e.id_color,
            color_name: e.color_name,
            id_size: e.id_size,
            id_item: e.item_id,
            id_category: e.id_category,
            size_name: e.size_name,
            price: e.sale_price,
            id_brand: e.id_brand,
            brand_name: e.brand_name,
            qty: 1,
            price_total: e.sale_price
          })
        } else {
          const index = findIndex(this.details,{id_variant:e.id})
          this.$nextTick(()=>{
            this.details[index].qty += 1
            this.details[index].price_total = this.details[index].qty * this.details[index].price
          })
        }
      }
      this.details = dt
      if(this.$refs.itemsModal) {
        this.$refs.itemsModal.hide()
      }
      this.$nextTick(()=>{
        this.discPercentChange()
      })
    },
    discPercentChange(){
      this.formData.disc_total = this.subTotal * this.formData.disc_percent / 100
      this.paymentTypeChange()
    },
    discTotalChange(){
      this.formData.disc_percent = this.formData.disc_total / this.subTotal * 100
      this.paymentTypeChange()
    },
    itemsEdit(key){
      this.editIndex = key
      const item = this.details[key]
      const it = {
        id_variant:item.id_variant,
        id_item:item.id_item,
        code:item.code,
        brand: {label:item.brand_name,value:item.id_brand},
        color: {label:item.color_name,value:item.id_color},
        size: {label:item.size_name,value:item.id_size},
        qty:item.qty,
        code_article:item.code_article,
        price:item.price,
        price_total:item.price_total
      }
      this.formEdit = it
      this.$refs.editModal.show()
    },
    changeItemSize(){
      const fe = this.formEdit
      const f = find(this.items,{item_id:fe.id_item,id_color:fe.color.value,id_size:fe.size.value})
      if(f) {
        this.formEdit.id_variant = f.id
        this.formEdit.id_item = f.item_id
        this.formEdit.code = f.code
        this.formEdit.price = f.sale_price
        this.formEdit.price_total = f.sale_price * parseFloat(fe.qty)
      }
    },
    submitEdit(){
      const index = this.editIndex
      const fe = this.formEdit
      this.details[index].id_variant = fe.id_variant
      this.details[index].qty = fe.qty
      this.details[index].id = fe.id
      this.details[index].code = fe.code
      this.details[index].price = fe.price
      this.details[index].price_total = fe.price_total
      this.details[index].id_size = fe.size.value
      this.details[index].size_name = fe.size.label
      this.$refs.editModal.hide()
      this.editIndex = -1
    },
    removeItems(key){
      this.details.splice(key, 1)
      this.discPercentChange()
    },
    findTransactionByUniqid(uniqid){
      const vm = this
      const data = find(this.$store.state.pos.local_transactions,{uniqid})

      if(data) {
        vm.formData.uniqid = uniqid
        vm.formData.date = data.date
        vm.formData.code = data.code
        vm.formData.code_ref = data.code_ref
        vm.formData.note = data.note
        vm.formData.disc_percent = data.disc_percent
        vm.formData.disc_total = data.disc_total
        vm.formData.id_payment_partner = data.id_payment_partner
        vm.formData.payment_amount = data.payment_amount
        vm.formData.is_sync = false
        vm.formData.type_transaction = data.type_transaction
        vm.formData.kind = 2
        vm.details = data.details
      }
    },
    generatedCode(){
      const ifNotAvailable = "NJ"+this.$moment().format("YYMM")+""+helper.randomize()
      return ifNotAvailable
      /*
      const generatedCode = localStorage.getItem(`saleNumbering`)||ifNotAvailable
      return this.incrementString(generatedCode)
      */
    },
    incrementString (str) {
      // Find the trailing number or it will match the empty string
      const count = str.match(/\d*$/);
      // Take the substring up until where the integer was matched
      // Concatenate it to the matched count incremented by 1
      // return str.substr(0, count.index) + (++count[0]);
      return str.substr(0, count.index) + ("0000" + (++count[0])).slice(-4)
    },
    barcodeScanned(barcode){
      const vm = this
      const data = find(vm.items,{code:barcode})
      if(data){
        vm.selectedItems = [data]
        vm.$nextTick(()=>{
          vm.importing()
        })
      } else {
        vm.notify("Barang Tidak Ditemukan","Maaf","danger")
      }
    },
    paymentTypeChange(){
      const index = findIndex(this.paymentTypes,{value:this.formData.id_payment_partner})
      if(index>=0){
        const {is_cash} = this.paymentTypes[index]
        if(is_cash==0) this.formData.payment_amount = this.grandTotal
        else this.formData.payment_amount = 0
      } else {
        this.formData.payment_amount = 0
      }
    },
    localTransactionImported(item){
      this.$refs.transactionModal.hide()
      this.$router.replace({query:{uniqid:item.uniqid}})
    },
    serverTransactionImported(item){
      this.$refs.transactionModal.hide()
      this.$router.replace({query:{id:item.id}})
    },
    async getTransactionFromServer(id){
      const vm = this
      vm.$store.dispatch('pos/getTransactionFromServer',{id,type:3})
      .then((data)=>{
        if(data) {
          vm.formData.uniqid = data.uniqid
          vm.formData.id_origin = data.id
          vm.formData.date = data.date
          vm.formData.code = data.code
          vm.formData.code_ref = data.code_ref
          vm.formData.note = data.note
          vm.formData.disc_percent = data.disc_percent
          vm.formData.disc_total = data.disc_total
          vm.formData.id_payment_partner = data.id_payment_partner
          vm.formData.payment_amount = data.payment_amount
          vm.formData.is_sync = false
          vm.formData.type_transaction = data.type_transaction
          vm.formData.kind = 2
          vm.details = map(data.details,(n)=>{
            const dn = JSON.parse(JSON.stringify(n))
            dn.id_origin = dn.id
            delete dn.id
            return dn
          })
        }
      })
    }
  },
  mounted(){
    if(this.paymentTypes.length>0)
      this.formData.id_payment_partner = this.paymentTypes[0].value
    if(this.$route.query.uniqid) {
      this.findTransactionByUniqid(this.$route.query.uniqid)
    } else if(this.$route.query.id){
      this.getTransactionFromServer(this.$route.query.id)
    } else {
      this.formData.code_ref = this.generatedCode()
    }
    if(localStorage.getItem('notaPrinter')||null) this.qzSetPrinter(localStorage.getItem('notaPrinter'))
  }
}
</script>

<style>

</style>