const JsBarcode = require('jsbarcode')
const set_text = (text, length) => {
  let texts = text.toUpperCase();
  const length_text = texts.length;
  const count_loop = (length - length_text) / 2;
  for (let i = 0; i < count_loop; i++) {
    texts = texts + " ";
  }
  for (var z = 0; z < count_loop; z++) {
    texts = " " + texts;
  }
  return texts;
};

const set_text_right = (text, length) => {
  let texts = text.toUpperCase();
  const length_text = texts.length;
  const count_loop = length - length_text;
  for (let i = 0; i < count_loop; i++) {
    texts = " " + texts;
  }
  return texts;
};

const set_text_left = (text, length) => {
  let texts = text.toUpperCase();
  const length_text = texts.length;
  const count_loop = length - length_text;
  for (let i = 0; i < count_loop; i++) {
    texts = texts + " ";
  }
  return texts;
};

const paginate = (array, page_size, page_number) => {
  return array.slice((page_number - 1) * page_size, page_number * page_size);
};

const textToBase64Barcode = (text) => {
  const canvas = document.createElement("canvas");
  JsBarcode(canvas, text, {format: "CODE128",fontSize:30});
  const renders = canvas.toDataURL("image/png");
  return renders.split(",")[1]
};

export {
  set_text,
  set_text_right,
  set_text_left,
  paginate,
  textToBase64Barcode,
};
