<template>
  <div>
    <BTable sticky-header no-border-collapse selected-variant="primary" selectable select-mode="single" @row-selected="rowSelected" :fields="fields" :items="items" responsive hover outlined>
      <template #cell(is_sync)="{item}">
        <BBadge v-if="item.is_sync" variant="success">Synced</BBadge>
        <BBadge v-else variant="danger">Not Synced</BBadge>
      </template>
      <template #cell(date)="{item}">
        {{item.date | fulldate}}
      </template>
      <template #cell(grand_total)="{item}">
        <span>Rp.{{item.grand_total | formatNumber}}</span>
      </template>
    </BTable>
    <div class="d-flex justify-content-end">
      <BButton variant="primary" @click="choose">Pilih</BButton>
    </div>
  </div>
</template>

<script>
import {BTable,BButton,BBadge} from 'bootstrap-vue'
export default {
  components:{BTable,BButton,BBadge},
  data(){
    return {
      fields:[
        {key:"code_ref",label:"No. Transaksi"},
        {key:"date",label:"Tanggal"},
        {key:"payment_partner",label:"Pembayaran"},
        {key:"grand_total",label:"Total Harga"},
        {key:"is_sync",label:"Status"},
      ],
      selected:[]
    }
  },
  computed:{
    items(){
      return this.$store.getters['pos/transactionByFilter']({type_transaction: 3})
    }
  },
  methods:{
    rowSelected(item){
      this.selected = item
    },
    choose(){
      if(this.selected.length==0) return this.notify("Pilih salah satu transaksi terlebih dahulu!","Oops!","danger")
      this.$emit('imported', this.selected[0])
    }
  }
}
</script>

<style>

</style>